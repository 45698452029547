
import { apiGameTopicDel, apiGameTopicLists, apiGameTopicStatus, apiGameTopicImport } from '@/api/setting'
import { RequestPaging } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import * as XLSX from "xlsx";
@Component({
    components: {
        LsDialog,
        LsPagination,
        ExportData
    }
})
export default class TopicLists extends mixins() {
    queryObj = {
        title: ''
    }
    pager = new RequestPaging()
    apiGameTopicLists = apiGameTopicLists

    initAppList = [{id: '', name:"全部"}]

    getList(page?: number) {
        page && (this.pager.page = page)
        return this.pager.request({
            callback: apiGameTopicLists,
            params: {
                ...this.queryObj,
                aid: 0,
            }
        })
    }
    handleReset() {
        this.queryObj = {
            title: ''
        }
        this.getList()
    }
    handleStatus(value: number, id: number) {
        apiGameTopicStatus({
            id,
            is_show: value,
            aid: 0
        }).then(() => {
            this.getList()
        })
    }

    handleDelete(id: number) {
        apiGameTopicDel({
            id,
            aid: 0
        }).then(() => {
            this.getList()
        })
    }

    async created() {
        // await this.getGameAppList(this.initAppList)
        this.loadData()
    }

    loadData() {
        return Promise.all([
            this.getList()
        ])
    }

    async onTabClick(event: any) {
        const loading = this.$loading({
            lock: true,
            text: '加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)',
            customClass: 'switch-loading'
        })
        await this.loadData()
        loading.close()
    }

    handleXLSXImport(event: any) {
        let wb: any; //读取完成的数据
        let rABS = false; //是否将文件读取为二进制字符串
        const files = event.target.files;
        console.log('files', files)
        if (files && files.length <= 0) {
            return;
        }
        let f = files[0];
        let reader = new FileReader();
        const that = this
        reader.onload = async function (e: any) {
            let data = e.target.result;
            if (rABS) {
                wb = XLSX.read(btoa(that.fixdata(data)), {
                    //手动转化
                    type: "base64",
                });
            } else {
                wb = XLSX.read(data, {
                    type: "binary", //以二进制的方式读取
                });
            }
            let result: any[] = [];
            wb.SheetNames.forEach((sheetName: string, sheetIndex: number) => {
                const sheetData = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
                result.push(sheetData);
            });
            console.log('result', result)
            await apiGameTopicImport({
                topics: result.flat().map((e) => {
                    const o: any = {};
                    o.correct_option = e["答案"];
                    o.title = e["题目"];
                    o.option = {
                        0: e["A"],
                        1: e["B"],
                        2: e["C"],
                        3: e["D"],
                    };
                    return o;
                }),
                aid: 0
            });
            that.loadData()
        }
        if (rABS) {
            reader.readAsArrayBuffer(f);
        } else {
            reader.readAsBinaryString(f);
        }
    }

    fixdata(data: any) {
        //文件流转BinaryString
        var o = "",
            l = 0,
            w = 10240;
        for (; l < data.byteLength / w; ++l)
            o += String.fromCharCode.apply(
            null,
            // @ts-ignore
            new Uint8Array(data.slice(l * w, l * w + w))
        );
        // @ts-ignore
        o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
        return o;
    };
}
